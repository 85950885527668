import styled from 'styled-components'
import Box from './Box'

const CardFrame = styled(Box)``

CardFrame.defaultProps = {
  backgroundImage: 'linear-gradient(180deg, #F7F8FB 0%, #F0F2F6 100%)',
  borderColor: 'gray200',
  borderRadius: 24,
  // Apply border-radius to all children
  overflow: 'hidden',
  isolation: 'isolate',
}

export default CardFrame
